/* @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&family=Manrope:wght@300;400;500;600;700;800&family=Poppins&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Manrope:wght@300;400;500;600;700;800&family=Poppins&display=swap'); */


*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    transition: .2s ease all;
    scroll-behavior: smooth;
    font-family: 'Archivo Black', sans-serif;
    font-family: 'Be Vietnam Pro', sans-serif;
    font-family: 'Manrope', sans-serif;
    font-family: 'Poppins', sans-serif;

    /* font-family: 'Archivo Black', sans-serif;
    font-family: 'Be Vietnam Pro', sans-serif;
    font-family: 'Figtree', sans-serif;
    font-family: 'Manrope', sans-serif;
    font-family: 'Poppins', sans-serif; */

}
body{
    width: 100%;
    height: 100vh;
}
:root{
    /* colours  */
    /* --gc1:; */
    /* --gc2:; */
    /* --bg1:; */
    /* --bg2:; */
    --btn-bg:#4090b1;
    --btn-arrow-bg:#226d8c;
    --d-para-clr:#898989;
    --border-clr:#37B2E0;
    --img-border-clr:#B15050;
    --light-heading-clr:#363a3d;
    --cg-box-bg:#D5EEF3;
    
    /* sizez  */
    /* --main-title:; */
    --main-title-size:3rem;
    --title-size:1.4rem;
    --title-des-size: .9rem;
    --small-size:.7rem;
    /* --home-icon-size:; */
    /* --underline-width:; */
    /* --underline-on-hover:; */

}

/* ------------------alert----------------------- */
.alert{
    color: #055160;
    width: max-content;
    background-color: #cff4fc;
    padding: 1rem;
    margin: auto;
    position: relative;
    top: 10vw;
    border-radius: 1vw;
    text-align: left;
    border: .1rem solid #cff4fc ;
    border-left: .5vw solid #055160;
}
.alert h1{
    font-size: 2rem;
    margin-bottom: 1rem;
}
.alert p{
    font-size: 1rem;
    line-height: 2rem;
}
.links{
    margin-top: 1.2rem;
    display: flex;
    justify-self: left;
}
.links i{
    font-size: 1.5rem;
    margin-right:2rem;
}
.facebook{
    color: #1877F2;
}
.linkedIn{
    color:  #0072b1;
}
.email{
    color: #CB3C32;
}

/* ----------------check-------------------- */
/* .active-navlink{
    text-decoration: none;
    color: #333;
}
ul{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 4rem;
    list-style: none;
}
ul li{
    background-color: rgba(0, 0, 0, .2);
    width: max-content;
    padding: 0 .5rem;
    color: rgba(1, 1, 1, .8);
    text-decoration: none;
}
.box{
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
    padding: 2rem 3rem;
}
.products{
    background-color: rgba(0, 0, 0, .18);
    border-radius: .5rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    text-align: left;
    cursor: pointer;
} */

/* ---------common------------------------- */
.main{
    padding: 2rem;
    margin-top: 3rem;
    width: 100%;
    height: 70vh;
    text-align: left;
}
.btnHere{
    padding: .2rem .5rem;
    text-decoration: none;
    background-color: var(--btn-bg);
    width: max-content;
    margin-top: 1rem;
    width: max-content;
    border-radius: 1.5rem;
} 
.button button{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border: none;
    outline: none;
    border-radius: 1.5rem;
    cursor: pointer;
    padding: 0 .8rem;
    color: #fff;
    background-color: var(--btn-bg);
}
.button i{
    margin-left: .5rem;
    text-decoration: none;
    background-color: var(--btn-arrow-bg);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    position: relative;
    right: -1rem;
}
.button:hover button{
    background-color: var(--btn-arrow-bg);

} 
::-webkit-scrollbar {
    display: none;
}
.h1{
    font-size: var(--main-title-size);
    line-height: 3rem;
    font-weight: 600;    
    line-height: 3rem;

}
.h2{
    margin-bottom: 1rem;
    font-size: var(--title-size);
    line-height: 3rem;
}
.p{
    font-size: var(--title-des-size);
    color: var(--d-para-clr);
    margin-bottom: 1rem;
    line-height: 1.5rem;
}
/* ----------------header---------------------- */
header{
    width: 100%;
    height: 4rem;
    position: fixed;
    top: 0;
}
header nav{
    width: 100%;
    height: 100%;
}
header nav ul{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    height: 100%;
}
header nav ul li{
    margin-left: 2rem;
    font-size: var(--title-des-size);
    color: var(--light-heading-clr);
}

/* --------------------home------------------- */

.home{
    background-image: url('../src/images/img1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.gradientbg{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.homeLinks{
    flex:1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}
.homeLinks a{
    border: 1px solid var(--d-para-clr);
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-top: .5rem;
    padding: 1rem;
}
.homeLinks a i{
    font-size: var(--title-des-size);
    color: var(--d-para-clr)
}
.homeContent{
    flex: 15;
    height: 100%;
    padding: 5rem 0;
}
.homeContent h1{
    margin-left: 4rem;
    background-color: #fff;
    position: relative;
    z-index: 2;
    width: max-content;
}
.border{
    background-color: transparent;
    border: 3px solid var(--border-clr);
    width: 10rem;
    height: 20rem;
    position: absolute;
    top: 6rem;
    left: 6rem;
    z-index: 1;
}
.homeContent .with{
    font-size: var(--title-size);
    color: var(--d-para-clr);
}
.homeContent h2{
    margin-left: 4rem;
    background-color: #fff;
    position: relative;
    z-index: 1;
    margin-bottom: 1rem;
}
.homeContent p{
    margin-left: 11rem;
    color: var(--d-para-clr);
    width: 50%;
}
.homeContent .btnHere{
    margin-left: 11rem;
}
/* ----------------imageGrid------------------- */
.imageGrid{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;  
}
.imageGridImages,.imageGridContent p{
    line-height: 1.5rem;
    margin-bottom: 2rem;
}
/* ---------------services--------------------- */
#service{
    display: flex;
    flex-direction: column;
}
.serviceHeading{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.serviceComponents{
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
}
.serviceComponent{
    border: 1px solid var(--border-clr);
    padding: 1rem 2rem;
    margin: 0 1rem;
}
.serviceComponents .arrow{
    display: flex;
    align-self: center;
    justify-self: center;
    cursor: pointer;
    margin: 0 1rem;
    position: relative;
}
.arrowActive{
    color: var(--d-para-clr);
    width: 3rem;
    height: 3rem;
    border: 2px solid var(--d-para-clr);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 1rem;
}
/* ------------------footer-------------------------- */
#footer{
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    gap: 2rem;
    height: max-content;
}
#footer .footerLogo,#footer .footerHome,
#footer .footerProducts,#footer .footerContacts{
   display: flex;
   flex-direction: column; 
   padding: 0 2rem 0 0;
}
.footerContactLinks .icon a{
    display: flex;
    align-items: center;
    justify-content: center;
}
.footerContactLinks .icon a i{
    margin-right: 1rem;
}
#footer a{
    text-decoration: none;
    margin-bottom: 1rem;
    color: #333;
    width: max-content;
}
#footer a:hover{
    color: var(--d-para-clr);
}
.footerContactLinks,.footerHomeLinks,.footerProductLinks{
    display: flex;
    flex-direction: column;
}
/* .footerContactLinks,.footerHomeLinks,.footerProductLinks{
    margin-left: 1rem;
} */

.logo{
    width: 100%;
}
.logo img{
    width: 10rem;
    height: 5rem;
    display: flex;
    align-self: center;
    margin-bottom: 2rem;
}
.logo p{
    line-height: 1.5rem;
}
@media(max-width: 766px) {

    /* ----------------------alert-------------------- */
    .alert h1{
        font-size: 1rem;
    }
    .alert p{
        font-size: .8rem
    }
    .alert{
        padding: 3vw;
    }
    
}